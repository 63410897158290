(function () {
    "use strict";

    // @ngInject
    function CompanySettingsControllerCtor($scope, $injector, AppStates, UsersManager, DeviceService, StatsigService) {
        this.constructor.$super.call(this, $scope, $injector);
        this.__objectType = 'CompanySettingsController';
        this.isInAppBrowser = DeviceService.isInAppBrowser();
        this.user = UsersManager.getCurrUser();

        // ng2react  - Company settings preferences
        this.companyPreferencesVariant = 'pending';
        StatsigService.isGateEnabled('ng2react-company-settings-preferences').then(function (isEnabled) {
            this.companyPreferencesVariant = isEnabled ? 'react' : 'angular';
        }.bind(this)).catch(function () {
            this.companyPreferencesVariant = 'angular';
        }.bind(this));

    }

    Controllers.CompanySettingsController = Class(Controllers.BaseController, {
        constructor: CompanySettingsControllerCtor,
    });
}());
